<template>
  <div>
    <v-row no-gutters v-if="fieldsLoading">
      <v-col v-for="n in 12" :key="n" cols="4">
       <v-sheet class="my-2 px-2">
        <v-skeleton-loader class="mx-auto" type="image" height="75"></v-skeleton-loader>
      </v-sheet>
      </v-col>
    </v-row>
    <div v-else class="mt-1">
      <v-card class="pa-0" flat tile v-if="!activeHtmlLink">
        <v-row class="mx-0">
          <v-col cols="12" class="pa-1" sm="12" md="6" lg="4">
            <v-text-field append-icon="mdi-magnify" :label="$t('message.common.search')" hide-details single-line outlined dense v-model="fileSearchTerm" @input="debouncedSearchPublishedFiles"></v-text-field>
          </v-col>
          <v-breadcrumbs class="pa-0 px-3 custom_breadcrumb" v-if="breadCrumbItems.length > 1 && !fileSearchTerm" :items="breadCrumbItems">
            <template v-slot:item="{ item }">
              <v-hover v-slot:default="{ }">
                <v-breadcrumbs-item :class="getBreadCrumbItemIndex(item) !== (breadCrumbItems.length - 1) ? 'pointer-hand custom_breadcrumb_item_style' : ''" @click="getBreadCrumbItemIndex(item) !== (breadCrumbItems.length - 1) ? navigateToItem(item) : null">
                  <span v-if="getBreadCrumbItemIndex(item) === (breadCrumbItems.length - 1)">
                    <strong>{{ item.text || item.original_name || '' }}</strong>
                  </span>
                  <span v-else>{{ item.text || item.original_name || '' }}</span>
                </v-breadcrumbs-item>
              </v-hover>
            </template>
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </v-row>
        <v-progress-linear indeterminate v-show="loading || searchLoading"></v-progress-linear>
        <v-row class="mx-0" v-if="fileSearchTerm ? listOfSearchResult.length > 0 : listOfDocuments.length > 0">
          <template v-for="(document) in fileSearchTerm ? listOfSearchResult : listOfDocuments">
            <v-col v-if="document.id" cols="3" class="pa-0" :key="`${document.id}_document`">
              <v-card tile flat class="pa-0 ma-1" @click="document.is_dir ? getChildren(document) : loadHtmlFile(document)">
                <div class="text-center pt-1">
                  <v-avatar slot="48">
                    <v-icon :color="document.is_dir ? '#f6b21b' : ''" size="48">{{ document.is_dir ? 'mdi-folder' : 'mdi-file-document' }}</v-icon>
                  </v-avatar>
                </div>
                <v-card-text class="text-center pa-0 font-weight-medium">{{ document.text || document.original_name || '' }}</v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-row>
        <v-alert prominent text v-else-if="!loading || !searchLoading" type="info" class="mt-2 mx-1" >
          <v-row align="center">
            <v-col class="grow">{{ $t('message.common.noResults') }}</v-col>
          </v-row>
        </v-alert>
      </v-card>
      <v-card v-else flat>
        <v-row class="mx-0">
          <v-col cols="10" class="pa-1"><strong>{{$t('message.common.filename')}}:</strong><br/> {{activeHtmlTitle}}</v-col>
          <v-col cols="2" class="pa-1 text-right">
            <v-btn small color="secondary" icon @click="activeHtmlLink = ''; activeHtmlTitle = ''">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="mx-0">
          <v-progress-linear indeterminate color="primary" v-if="iframeSrcLoading"></v-progress-linear>
          <v-responsive :aspect-ratio="16/9" :style="`visibility: ${iframeSrcLoading ? 'hidden' : ''}`">
            <iframe @load="iframeSrcLoading = false" class="files-html" width="100%" :src="activeHtmlLink"></iframe>
          </v-responsive>
          <!-- height="100%" v-if="activeHtmlLink"-->
          <!-- <v-card-text class="pa-1 custom_scroll" v-html="activeHtmlItem"></v-card-text> -->
          <!-- <v-card flat class="pa-1 custom_scroll" v-html="activeHtmlItem"></v-card> -->
        </v-row>
      </v-card>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import VueCookie from 'vue-cookie'
const hostAppApi = axios.create({
  headers: {
    common: {
      Authorization: `Bearer ${VueCookie.get(process.env.VUE_APP_HOST_TOKEN)}`
    }
  }
})
export default {
  data () {
    return {
      fieldsLoading: false,
      /* For files */
      listOfDocuments: [],
      loading: false,
      breadCrumbItems: [{ id: 0, text: this.$t('message.layout.home'), isHome: true }],
      activeFolder: null,
      activeHtmlItem: null,
      activeHtmlTitle: '',
      removeFromIndex: 0,
      hostRef: this.$cookie.get('AxentivPwa_host-ref'),
      ROOT001: 'root001',
      PARENT: 'parent',
      CHILDREN: 'childrens',
      activeHtmlLink: '',
      iframeSrcLoading: false,
      fileSearchTerm: '',
      searchLoading: false,
      userId: 0,
      listOfSearchResult: [],
      debouncedSearchPublishedFiles: null
    }
  },
  computed: {},
  created () {},
  mounted () {
    this.userId = this.$cookie.get(process.env.VUE_APP_HOST_USER) ? this.$cookie.get(process.env.VUE_APP_HOST_USER) : 0
    this.debouncedSearchPublishedFiles = this.$formatter.debounce(this.searchPublishedDocuments, 1000)
    if (!this.hostRef.includes(this.$store.state.common.host)) {
      this.hostRef += this.$store.state.common.host
    }
    this.loadRootFolder(true)
  },
  methods: {
    loadRootFolder (isFirstCall) {
      if (isFirstCall) this.fieldsLoading = true
      else this.loading = true
      hostAppApi.post(`https://${this.hostRef}/api/get_published_documents`, { id: this.ROOT001 }, { headers: { language: this.$i18n.locale } }).then((response) => {
        if (response && response.data) {
          if (!isFirstCall) this.breadCrumbItems.splice(1, this.breadCrumbItems.length)
          this.setDocumentsAndBreadCrumbItems(response.data[this.CHILDREN] || [], response.data[this.PARENT] || {})
          /* const rootObj = this.$formatter.cloneVariable(response.data)
          return
          this.getChildren(rootObj[0]) */
        }
      }).finally(() => {
        this.fieldsLoading = false
        this.loading = false
      })
    },
    getChildren (selectedFolder) { // it will have id and its parent id
      this.loading = true
      hostAppApi.post(`https://${this.hostRef}/api/get_published_documents`, { id: selectedFolder.id, parent_id: selectedFolder.parent_id }, { headers: { language: this.$i18n.locale } }).then((response) => {
        if (response && response.data) {
          this.setDocumentsAndBreadCrumbItems(response.data, selectedFolder)
        }
      }).finally(() => {
        this.loading = false
        this.fieldsLoading = false
      })
    },
    setDocumentsAndBreadCrumbItems (documents, selectedFolder) {
      this.listOfDocuments = this.$formatter.cloneVariable(documents)
      // add folder to breadcrumb
      if (this.removeFromIndex !== 0) {
        this.breadCrumbItems.splice(this.removeFromIndex, this.breadCrumbItems.length)
        this.removeFromIndex = 0
      }
      this.activeFolder = this.$formatter.cloneVariable(selectedFolder)
      const obj = this.breadCrumbItems.find(x => x.id === selectedFolder.id)
      if (!obj) this.breadCrumbItems.push(selectedFolder)
    },
    navigateToItem (item) {
      this.removeFromIndex = this.getBreadCrumbItemIndex(item)
      if (item.isHome) this.$router.push('/dashboard')
      else if (item.id === this.ROOT001) this.loadRootFolder(false) // false sets isFirstCall to false
      else this.getChildren(item)
    },
    getBreadCrumbItemIndex (item) {
      return this.breadCrumbItems.indexOf(item)
    },
    loadHtmlFile (item) {
      // this.loading = true
      this.activeHtmlTitle = item.original_name
      this.iframeSrcLoading = true
      this.activeHtmlLink = item.downloadlink
      /* axios.get(item.downloadlink).then((response) => {
        this.loading = false
        this.activeHtmlItem = response.data
      }) */
    },
    searchPublishedDocuments () {
      if (!this.fileSearchTerm) this.listOfSearchResult = []
      this.searchLoading = true
      hostAppApi.post(`https://${this.hostRef}/api/search_published_documents`, { user_id: this.userId, search: this.fileSearchTerm })
        .then((res) => {
          this.listOfSearchResult = this.$formatter.cloneVariable(res.data)
        })
        .finally(() => {
          this.searchLoading = false
        })
    }
  },
  watch: {
    '$i18n.locale' (val) {
      this.navigateToItem(this.activeFolder)
    }
  }
}
</script>
<style>
.custom_breadcrumb_item_style {
  padding: 1px 2px;
  text-decoration: underline;
  color: blueviolet;
  border-radius: 2px;
}
.custom_scroll {
  max-height: 82vh;
  overflow-y: auto !important;
  overflow-x: auto !important;
}
.custom_breadcrumb > li.v-breadcrumbs__divider {
  padding: 0 !important;
}
.custom_breadcrumb > li.pointer-hand {
  cursor: pointer !important;
}
.files-html {
  height: 73vh !important;
}
</style>
